import { checkArrow } from "../../icons/svg.js";

interface Props {
  steps: number;
  activeStep: number;
}

export const Stepper: React.FC<Props> = ({ steps, activeStep }) => {
  return (
    <div className="stepper">
      {Array(steps)
        .fill(0)
        .map((_, index) => {
          const done = activeStep >= index;

          return (
            <div key={index} className={done ? "done" : undefined}>
              <div>
                <span className="step-label">Steg</span> {index + 1}
              </div>
              {done && checkArrow}
            </div>
          );
        })}
    </div>
  );
};
